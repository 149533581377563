import React from "react";
import PageWrapper from "../common/components/PageWrapper";


const DashboardContact = () => {
  return (
    <PageWrapper>

    </PageWrapper>
  );
};

export default DashboardContact;

import React from "react";
import PageWrapper from "../common/components/PageWrapper";
import { TermsandConditions } from "../PrivacyPolicy/components/constant";
import PrivacyPolicy from "../PrivacyPolicy/components/privacypolicy";


const DashboardTermsandConditions = () => {
  return (
    <PageWrapper>
      <PrivacyPolicy policy={TermsandConditions} />
    </PageWrapper>
  );
};

export default DashboardTermsandConditions;

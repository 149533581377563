import React, { memo, useState } from "react";
import styles from "../styles/Header.module.css";
import logo from "../../../assets/images/hrlogo.png";
import menuburger from "../../../assets/images/burger.png";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import useLocale from "../../../../utility/useLocal";
// import { useSelector } from "react-redux";

const Header = ({ handleLanguageChange, language }) => {
  // const { locale } = useSelector((state) => state.user);
  const locale = useLocale();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 820px)" });
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle menu function
  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  const renderNavLink = (path, label) => (
    <a onClick={() => navigate(path)} className={styles.navLink}>
      {label}
    </a>
  );

  const renderHeader = () => (
    <div className={styles.nav}>
      {renderNavLink("/", locale.Home)}
      {renderNavLink("/DashBoardAbout", locale.About)}
      {renderNavLink("/DashBoardServices", locale.Services)}
      <div className={styles.privacy}>
        {locale.PrivacyPolicy}
        <div className={styles.privacyBox}>
          {/* {renderNavLink("/DashBoardAbout", locale.PrivacyPolicy)} */}
          {/* {renderNavLink("/DashBoardAbout", "Terms & Conditions")} */}
          <a
            onClick={() => navigate("/DashboardPrivacyPolicy")}
            className={styles.dropdownItem}
          >
            Privacy Policy
          </a>
          <a
            onClick={() => navigate("/dashboardTermsandConditions")}
            className={styles.dropdownItem}
          >
            Terms & Conditions
          </a>
        </div>
      </div>
      <select
        onChange={(e) => handleLanguageChange(e.target.value)}
        className="language-selector"
        style={{ padding: "5px", borderRadius: "4px" }}
        value={language}
      >
        <option value="en">🌐 English</option>
        <option value="hi">🌐 Hindi</option>
      </select>
    </div>
  );

  return (
    <header className={styles.header}>
      <div className={styles.logoContainer}>
        <img src={logo} alt="Logo" className={styles.logoImage} />
        {isMobile && (
          <div className={styles.menuWrapper}>
            <img
              src={menuburger}
              alt="Menu"
              className={styles.menuBurgerIcon}
              onClick={toggleMenu}
            />
          </div>
        )}
      </div>
      {(isMobile && isMenuOpen) || !isMobile ? renderHeader() : null}
    </header>
  );
};

export default memo(Header);

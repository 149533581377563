import React from "react";
import styles from "../styles/DigitallySignedCertificate.module.css";
import certifiedsms from "../../../assets/images/certifiedsms.png";
import certifiedsms1 from "../../../assets/images/certifiedsms1.png";
import useLocale from "../../../../utility/useLocal";
function DigitallySignedCertificate() {
  const locale = useLocale();
  return (
    <div className={styles.DigitallySignedCertificate}>
      <div className={styles.innerWrapper}>
        <div className={styles.headingWrapper}>
          <span className={styles.heading}>
           {locale.Certified_SMS_Heading}
          </span>
        </div>
        <div className={styles.contentWrapper}>
          <span className={styles.content}>
  {locale.Certified_SMS_Content1}
          </span>
        </div>
        <div className={styles.cardWrapper}>
          <div className={styles.card}>
            <img
              src={certifiedsms}
              alt="Digital Certificate"
              className={styles.cardImage}
            />
            <h3 className={styles.cardTitle}>
              {locale.Certified_SMS_Card1_Heading}
            </h3>
            <p className={styles.cardContent}>
            {locale.Certified_SMS_Card1_Content1}
            </p>
            <p className={styles.cardButton}>{locale.ReadMore}</p>
          </div>
          <div className={styles.card}>
            <img
              src={certifiedsms1}
              alt="Digital Certificate"
              className={styles.cardImage}
            />
            <h3 className={styles.cardTitle}>
            {locale.Certified_SMS_Card2_Heading}
            </h3>
            <p className={styles.cardContent}>
            {locale.Certified_SMS_Card2_Heading}
            </p>
            <p className={styles.cardButton}>Read More</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DigitallySignedCertificate;

import { toast } from "react-hot-toast";
import { GetProfileApi, viewcert } from "../app/API";
import moment from "moment";
export const formatDate = (inputDate) => {
	// Create a moment object for the input date
	const date = moment(inputDate);

	// Check if the date is valid
	if (!date.isValid()) {
		return "N/A"; // Return empty string if the date is invalid
	}

	// Create a moment object for today's date
	const today = moment();

	// Check if the input date is the same as today
	if (date.isSame(today, "day")) {
		// Return only the time if it's today
		return date.format("HH:mm:ss");
	} else {
		// Otherwise, return the full date and time
		return date.format("MMM DD, YYYY");
	}
};
export const NumberRegexPattern = /^\d{10}$/;
export const EmailRegexPattern =
	/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const downloadPdf = (base64String, label) => {
	let fileExtension;
	let mimeType;

	// Determine file type and set the appropriate MIME type and file extension
	if (base64String.startsWith("JVBERi0")) {
		// Common PDF base64 header
		fileExtension = ".pdf";
		mimeType = "application/pdf";
	} else if (base64String.startsWith("/9j/")) {
		// Common JPEG base64 header
		fileExtension = ".jpg";
		mimeType = "image/jpeg";
	} else if (base64String.startsWith("iVBORw0KGgo")) {
		// Common PNG base64 header
		fileExtension = ".png";
		mimeType = "image/png";
	} else {
		console.error("Unsupported file type");
		return;
	}

	let name = label;
	const filePath = name?.includes(fileExtension)
		? name
		: `${name}${fileExtension}`;

	// Create a Blob from the base64 string
	const byteCharacters = atob(base64String);
	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);
	const blob = new Blob([byteArray], { type: mimeType });

	// Create a link element and trigger download
	const link = document.createElement("a");
	link.href = window.URL.createObjectURL(blob);
	link.download = filePath;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const getUserInfo = async (dispatch) => {
	const token = await localStorage.getItem("key");
	const result = await GetProfileApi(token);
	if (result?.response === "ok") {
		dispatch({ type: "USERINFO", payload: result?.data });
	}
};

export const getCertificate = async (type, id) => {
	try {
		let token = localStorage.getItem("key");
		let raw = JSON.stringify({ type: type, id: [id] });
		await viewcert(raw, token);
	} catch (error) {
		console.error(error);
	}
};
export const copyExcelData = (dataArray) => {
	if (!dataArray.length > 0) {
		return;
	}
	const columnDelimiter = ",";
	const lineDelimiter = "\n";
	let csv = "";

	// Create header
	csv += Object.keys(dataArray[0]).join(columnDelimiter);
	csv += lineDelimiter;

	// Create rows
	dataArray.forEach((item) => {
		csv += Object.values(item).join(columnDelimiter);
		csv += lineDelimiter;
	});

	// Create a textarea element to copy the CSV data
	const textarea = document.createElement("textarea");
	textarea.textContent = csv;
	document.body.appendChild(textarea);

	// Copy the CSV data to the clipboard
	textarea.select();
	document.execCommand("copy");
	window.alert("Excel data copied to clipboard!");
	// Remove the textarea element
	document.body.removeChild(textarea);
};

const exportToCSV = (dataArray, fileName) => {
	const columnDelimiter = ",";
	const lineDelimiter = "\n";
	let csv = "";

	// Create header
	csv += Object.keys(dataArray[0]).join(columnDelimiter);
	csv += lineDelimiter;

	// Create rows
	dataArray.forEach((item) => {
		csv += Object.values(item).join(columnDelimiter);
		csv += lineDelimiter;
	});

	// Create a blob and initiate download
	const blob = new Blob([csv], { type: "text/csv" });
	const link = document.createElement("a");
	link.href = URL.createObjectURL(blob);
	link.download = fileName;
	link.click();
};

export const excelDownload = (data, fileName) => {
	if (!data.length > 0) {
		return;
	}
	exportToCSV(
		data,
		fileName + ".csv" ?? "CERTIFI | Credit Charged History.csv"
	);
};

const generateExcelHTML = (dataArray, fileName) => {
	let html = `
	  <h2 style="text-align: center;">${fileName}</h2>
	  <table style="width: 100%; border-collapse: collapse; border: 1px solid black;">
		<thead>
		  <tr style="background-color: #f2f2f2;">
	`;

	// Create header row
	Object.keys(dataArray[0]).forEach((key) => {
		html += `<th style="border: 1px solid black; padding: 8px;">${key}</th>`;
	});
	html += "</tr></thead><tbody>";

	// Create data rows
	dataArray.forEach((item) => {
		html += "<tr>";
		Object.values(item).forEach((value) => {
			html += `<td style="border: 1px solid black; padding: 8px;">${value}</td>`;
		});
		html += "</tr>";
	});

	html += "</tbody></table>";
	return html;
};

export const printExcelFile = (data, fileName) => {
	if (!data.length > 0) {
		return;
	}
	const excelDataHTML = generateExcelHTML(
		data,
		fileName ?? "CERTIFI | Credit Charged History"
	);
	const printWindow = window.open("", "_blank");
	printWindow.document.write(excelDataHTML);
	printWindow.document.close();
	printWindow.print();
};

import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import Input from "../../../components/Input";
import Loader from "../../../components/Loader";
import { getCertificate } from "../../API";
import username from "../../assets/images/username.png";
const CertifiEmail = () => {
	const { locale } = useSelector((state) => state?.user);
	const navigate = useNavigate();
	const isMobile = useMediaQuery({
		query: "(max-width: 1024px)",
	});
	const [loginCredential, setLoginCredential] = useState({});
	const onChangeText = (val, key_name) => {
		setLoginCredential((previous) => ({
			...previous,
			[key_name]: val,
		}));
	};

	const handleSubmit = async () => {
		const { mob_num, receiver_otp } = loginCredential || {};
		if (!mob_num || !receiver_otp)
			return toast.error("Both fields are required!");
		const data = {
			mob_num: loginCredential?.mob_num,
			receiver_otp: loginCredential?.receiver_otp,
		};
		const result = await getCertificate(JSON.stringify(data));
		if (result?.response == "ok") {
			navigate("/certifi-email-details", {
				state: { data: result },
			});
		}
	};

	const getCertificateComponent = () => {
		return (
			<div
				className="about_container"
				style={{
					width: isMobile ? "86%" : "40%",
					backgroundColor: "#fff",
					margin: "auto",
					padding: "60px 20px",
					alignItems: "center",
					justifyContent: "center",
					alignContent: "center",
					borderRadius: 12,
					textAlign: "center",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
						margin: "auto",
					}}
				>
					<img
						alt="img"
						src={require("../../assets/images/logo.png")}
						style={{ margin: "auto" }}
					/>
					<span style={{ marginBottom: 30, marginTop: 20 }}>
						{locale?.CertifiedDesc}
					</span>
					<Input
						icon={username}
						placeholder={"Enter Phone Number"}
						onChangeText={onChangeText}
						key_name="mob_num"
						value={loginCredential?.mob_num}
						maxLength={10}
					/>
					<Input
						icon={username}
						placeholder={"Enter OTP"}
						onChangeText={onChangeText}
						key_name="receiver_otp"
						value={loginCredential?.receiver_otp}
						maxLength={8}
					/>
				</div>
				<div style={{ marginTop: 40 }}>
					<a onClick={handleSubmit} class="btn btn-1">
						{locale?.SubmitOTP}
					</a>
				</div>
			</div>
		);
	};

	return (
		<div className="wrapper">
			<img
				alt="img"
				src={require("../../assets/images/thumb.jpg")}
				style={{
					height: "100vh",
					width: "100%",
					opacity: 0.9,
					position: "absolute",
					zIndex: -1,
				}}
			/>
			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
					margin: "auto",
					alignItems: "center",
					height: "100vh",
				}}
			>
				<div
					className="about_card"
					style={{
						alignContent: "center",
					}}
				>
					{getCertificateComponent()}
				</div>
			</div>
		</div>
	);
};

export default CertifiEmail;

import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import Footer from "./components/Footer";
import HeroSectionAbout from "./components/HeroSectionAbout";
import DigitallySignedCertificate from "./components/DigitallySignedCertificate";
import UseCases from "./components/UseCases";
import PageWrapper from "./common/components/PageWrapper";
import useLocale from "../../../utility/useLocal";
const Dashboard = () => {
  const locale = useLocale();
  const {
    Banks_and_Insurance,
    Banks_and_Insurance_Content,
    Courts,
    Courts_Content,
    Government_Departments,
    Government_Departments_Content,
    General_Public,
    General_Public_Content,
    Educational_Institutions,
    Educational_Institutions_Content,
    Enterprises,
    Enterprises_Content,
    UseCases:UseCases1
  } = locale;
  return (
    <PageWrapper>
      <HeroSection />
      <HeroSectionAbout />
      <DigitallySignedCertificate />
      <UseCases
        heading={UseCases1}
        data={[
          {
            title: Banks_and_Insurance,
            content: Banks_and_Insurance_Content,
          },
          {
            title: Courts,
            content: Courts_Content,
          },
          {
            title: Government_Departments,
            content: Government_Departments_Content,
          },
          {
            title: General_Public,
            content: General_Public_Content,
          },
          {
            title: Educational_Institutions,
            content: Educational_Institutions_Content,
          },
          {
            title: Enterprises,
            content: Enterprises_Content,
          },
        ]}
      />
    </PageWrapper>
  );
};

export default Dashboard;

import AppUrl from "./AppUrl";

export const BASEURL = AppUrl.ROOT;
export const register = async (data) => {
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	try {
		const res = await fetch(AppUrl.REGISTERURL, {
			method: "POST",
			headers: myHeaders,
			body: data,
		});
		const res_1 = await res.json();
		return res_1;
	} catch (e) {
		return console.log(e);
	}
};
export const AggreementVerify = async (data) => {
	console.log("AggreementVerify,,,", data, AppUrl.payment);
	try {
		const response = await fetch(AppUrl.payment, {
			method: "POST",
			body: data,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const SecurityQuestion = async (token) => {
	try {
		const res = await fetch(AppUrl.SecurityQuestionURL, {
			method: "POST",
			headers: { Authorization: "Token " + token },
		});
		const res_1 = await res.json();
		return res_1;
	} catch (e) {
		return console.log(e);
	}
};
export const login = async (data) => {
	console.log("login,,,", data, AppUrl.LOGINURL);
	try {
		const response = await fetch(AppUrl.LOGINURL, {
			method: "POST",
			body: data,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const Reset = async (data) => {
	console.log("PasswordRest,,,", data, AppUrl.RESETPASSWORD);
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	try {
		const response = await fetch(AppUrl.RESETPASSWORD, {
			method: "POST",
			// headers: myHeaders,
			body: data,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const EmailVerified = async (data) => {
	console.log("PasswordRest,,,", data, AppUrl.EMAILVERIFIED);
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	try {
		const response = await fetch(AppUrl.EMAILVERIFIED, {
			method: "POST",
			body: data,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const WhiteListEmailVerified = async (url) => {
	console.log("emailverify,,,", url);
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	try {
		const response = await fetch(url);
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const ResetNewPassword = async (data) => {
	console.log("PasswordRest,,,", data, AppUrl.RESETNEWPASSWORD);
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	try {
		const response = await fetch(AppUrl.RESETNEWPASSWORD, {
			method: "POST",
			body: data,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const Accept_Reject = async (id, data, token) => {
	console.log("AccepttaskURL,,,", data, AppUrl.Accept + id + "/", token);
	try {
		const response = await fetch(AppUrl.Accept + id + "/", {
			method: "PUT",
			headers: { Authorization: "Token " + token },
			body: data,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const Compose = async (token) => {
	console.log(token, AppUrl.Compose);
	try {
		const res = await fetch(AppUrl.Compose, {
			method: "POST",
			headers: { Authorization: "Token " + token },
		});
		const res_1 = await res.json();
		return res_1;
	} catch (e) {
		return console.log(e);
	}
};
export const SendSMS = async (data, token) => {
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Authorization", `Token ${token}`);
	console.log(AppUrl.SendSMS);
	try {
		const res = await fetch(AppUrl.SendSMS, {
			method: "POST",
			headers: myHeaders,
			body: data,
		});
		const res_1 = await res.json();
		return res_1;
	} catch (e) {
		return console.log(e);
	}
};
export const ComposeOTPverify = async (data, token) => {
	console.log(data, AppUrl.ComposeOTPverify);
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Authorization", `Token ${token}`);
	try {
		const res = await fetch(AppUrl.ComposeOTPverify, {
			method: "POST",
			headers: myHeaders,
			body: data,
		});
		const res_1 = await res.json();
		return res_1;
	} catch (e) {
		return console.log(e);
	}
};
export const ApproveEmail = async (data, token) => {
	console.log(data, AppUrl.ApproveEmail);
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Authorization", `Token ${token}`);
	try {
		const res = await fetch(AppUrl.ApproveEmail, {
			method: "POST",
			headers: myHeaders,
			body: data,
		});
		const res_1 = await res.json();
		return res_1;
	} catch (e) {
		return console.log(e);
	}
};
export const DissApproveEmail = async (data, token) => {
	console.log(data, AppUrl.DissApproveEmail);
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Authorization", `Token ${token}`);
	try {
		const res = await fetch(AppUrl.DissApproveEmail, {
			method: "POST",
			headers: myHeaders,
			body: data,
		});
		const res_1 = await res.json();
		return res_1;
	} catch (e) {
		return console.log(e);
	}
};
export const payment = async (data, token) => {
	console.log(data, AppUrl.payment);
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Authorization", `Token ${token}`);
	try {
		const res = await fetch(AppUrl.payment, {
			method: "POST",
			headers: myHeaders,
			body: data,
		});
		const res_1 = await res.json();
		return res_1;
	} catch (e) {
		return console.log(e);
	}
};
// paymentRegistration
export const paymentRegistration = async (data) => {
	console.log(data, AppUrl.payment);
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	try {
		const res = await fetch(AppUrl.payment, {
			method: "POST",
			headers: myHeaders,
			body: data,
		});
		const res_1 = await res.json();
		return res_1;
	} catch (e) {
		return console.log(e);
	}
};
export const DissApproveSMS = async (data, token) => {
	console.log(data, AppUrl.DissApproveSMS);
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Authorization", `Token ${token}`);
	try {
		const res = await fetch(AppUrl.DissApproveSMS, {
			method: "POST",
			headers: myHeaders,
			body: data,
		});
		const res_1 = await res.json();
		return res_1;
	} catch (e) {
		return console.log(e);
	}
};
export const DisApproveEmail = async (data, token) => {
	console.log(data, AppUrl.DisApproveEmail);
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Authorization", `Token ${token}`);
	try {
		const res = await fetch(AppUrl.DisApproveEmail, {
			method: "POST",
			headers: myHeaders,
			body: data,
		});
		const result_1 = await res.json();
		return result_1;
	} catch (e) {
		return console.log(e);
	}
};
export const reqfrwdotp = async (data, token) => {
	console.log(data, AppUrl.reqfrwdotp);
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Authorization", `Token ${token}`);
	try {
		const res = await fetch(AppUrl.reqfrwdotp, {
			method: "POST",
			headers: myHeaders,
			body: data,
		});
		const result_1 = await res.json();
		return result_1;
	} catch (e) {
		return console.log(e);
	}
};
export const checkfrwdotp = async (data, token) => {
	console.log(data, AppUrl.checkfrwdotp);
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Authorization", `Token ${token}`);
	try {
		const res = await fetch(AppUrl.checkfrwdotp, {
			method: "POST",
			headers: myHeaders,
			body: data,
		});
		const result_1 = await res.json();
		return result_1;
	} catch (e) {
		return console.log(e);
	}
};
export const frwdmail = async (data, token) => {
	console.log(data, AppUrl.frwdmail);
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Authorization", `Token ${token}`);
	try {
		const res = await fetch(AppUrl.frwdmail, {
			method: "POST",
			headers: myHeaders,
			body: data,
		});
		const result_1 = await res.json();
		return result_1;
	} catch (e) {
		return console.log(e);
	}
};
export const whitelistmail = async (data, token) => {
	console.log(data, AppUrl.whitelistmail);
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Authorization", `Token ${token}`);
	try {
		const res = await fetch(AppUrl.whitelistmail, {
			method: "POST",
			headers: myHeaders,
			body: data,
		});
		const result_1 = await res.json();
		return result_1;
	} catch (e) {
		return console.log(e);
	}
};
export const ref_account = async (token) => {
	console.log("ref_account,,,", token, AppUrl.ref_account);
	try {
		const response = await fetch(AppUrl.ref_account, {
			method: "POST",
			headers: { Authorization: "Token " + token },
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const GetInbox = async (token) => {
	console.log("GetInbox,,,", token, AppUrl.GetInbox);
	try {
		const response = await fetch(AppUrl.GetInbox, {
			method: "POST",
			headers: {
				Authorization: "Token " + token,
			},
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const GetFailedSMS = async (token) => {
	console.log("GetFailedSMS,,,", token, AppUrl.GetFailedSMS);
	try {
		const response = await fetch(AppUrl.GetFailedSMS, {
			method: "POST",
			headers: { Authorization: "Token " + token },
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const GetFailedMail = async (token) => {
	console.log("GetFailedSMS,,,", token, AppUrl.GetFailedMail);
	try {
		const response = await fetch(AppUrl.GetFailedMail, {
			method: "POST",
			headers: { Authorization: "Token " + token },
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const GetSuccessSMS = async (token) => {
	console.log("GetSuccessSMS,,,", token, AppUrl.GetSuccessSMS);
	try {
		const response = await fetch(AppUrl.GetSuccessSMS, {
			method: "POST",
			headers: { Authorization: "Token " + token },
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const GetUnprocessSMS = async (token) => {
	console.log("GetUnprocessSMS,,,", token, AppUrl.GetUnprocessSMS);
	try {
		const response = await fetch(AppUrl.GetUnprocessSMS, {
			method: "POST",
			headers: { Authorization: "Token " + token },
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const GetUnprocessMail = async (token) => {
	console.log("GetUnprocessMail,,,", token, AppUrl.GetUnprocessMail);
	try {
		const response = await fetch(AppUrl.GetUnprocessMail, {
			method: "POST",
			headers: { Authorization: "Token " + token },
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const GetDisApprovedMail = async (token) => {
	console.log("GetDisApprovedMail,,,", token, AppUrl.GetDisApprovedMail);
	try {
		const response = await fetch(AppUrl.GetDisApprovedMail, {
			method: "POST",
			headers: { Authorization: "Token " + token },
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const GetSuccessMail = async (token) => {
	console.log("GetSuccessMail,,,", token, AppUrl.GetSuccessMail);
	try {
		const response = await fetch(AppUrl.GetSuccessMail, {
			method: "POST",
			headers: { Authorization: "Token " + token },
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const GetTariffplans = async (token) => {
	console.log("GetTariffplans,,,", token, AppUrl.GetTariffplans);
	try {
		const response = await fetch(AppUrl.GetTariffplans, {
			method: "POST",
			headers: { Authorization: "Token " + token },
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const GetPaymentHistory = async (token) => {
	console.log("GetPaymentHistory,,,", token, AppUrl.GetPaymentHistory);
	try {
		const response = await fetch(AppUrl.GetPaymentHistory, {
			method: "POST",
			headers: { Authorization: "Token " + token },
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const ViewInvoice = async (data, token) => {
	console.log("ViewInvoice,,,", token, AppUrl.ViewInvoice);
	try {
		const response = await fetch(AppUrl.ViewInvoice, {
			method: "POST",
			headers: { Authorization: "Token " + token },
			data: data,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const GetCreditCharge = async (data, token) => {
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Authorization", `Token ${token}`);
	console.log("GetCreditCharge,,,", token, AppUrl.GetCreditCharge);
	try {
		const response = await fetch(AppUrl.GetCreditCharge, {
			method: "POST",
			headers: myHeaders,
			data: data,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const GetProfileApi = async (token) => {
	console.log("GetProfileApi,,,", token, AppUrl.GetProfileApi);
	try {
		const response = await fetch(AppUrl.GetProfileApi, {
			method: "POST",
			headers: { Authorization: "Token " + token },
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};

export const updatereferralcode = async (body, token) => {
	console.log("updatereferralcode,,,", token, AppUrl.updatereferralcode);
	try {
		const response = await fetch(AppUrl.updatereferralcode, {
			method: "POST",
			headers: { Authorization: "Token " + token },
			body: body,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};

export const changeApprovalType = async (body, token) => {
	console.log("changeApprovalType,,,", token, AppUrl.changeApprovalType);
	try {
		const response = await fetch(AppUrl.changeApprovalType, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Token " + token,
			},
			body: body,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};

export const setmymobilenum = async (body, token) => {
	console.log("setmymobilenum,,,", body, AppUrl.setmymobilenum);
	try {
		const response = await fetch(AppUrl.setmymobilenum, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Token " + token,
			},
			body: body,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};

export const verifyotp = async (body, token) => {
	console.log("verifyotp,,,", body, AppUrl.verifyotp);
	try {
		const response = await fetch(AppUrl.verifyotp, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Token " + token,
			},
			body: body,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};

export const changeaddr = async (body, token) => {
	console.log("changeaddr,,,", body, AppUrl.changeaddr);
	try {
		const response = await fetch(AppUrl.changeaddr, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Token " + token,
			},
			body: body,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const verifysms = async (body) => {
	console.log("verifysms,,,", body, AppUrl.verifysms);
	try {
		const response = await fetch(AppUrl.verifysms, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: body,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const isemailavailable = async (body) => {
	console.log("isemailavailable,,,", body, AppUrl.isemailavailable);
	try {
		const response = await fetch(AppUrl.isemailavailable, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: body,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const registerplans = async (body) => {
	console.log("registerplans,,,", body, AppUrl.registerplans);
	try {
		const response = await fetch(AppUrl.registerplans, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: body,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const recipientCheck = async (body) => {
	console.log("recipientCheck,,,", body, AppUrl.recipientCheck);
	try {
		const response = await fetch(AppUrl.recipientCheck, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: body,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const requestmailresend = async (body) => {
	console.log("requestmailresend,,,", body, AppUrl.requestmailresend);
	try {
		const response = await fetch(AppUrl.requestmailresend, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: body,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const genpromo = async (token) => {
	console.log("genpromo,,,", token, AppUrl.genpromo);
	try {
		const response = await fetch(AppUrl.genpromo, {
			method: "POST",
			headers: { Authorization: "Token " + token },
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return null;
	}
};

export const getmypic = async (token) => {
	console.log("getmypic,,,", token, AppUrl.getmypic);
	try {
		const response = await fetch(AppUrl.getmypic, {
			method: "POST",
			headers: { Authorization: "Token " + token },
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return null;
	}
};

export const getsecques = async (token) => {
	console.log("getsecques,,,", token, AppUrl.getsecques);
	try {
		const response = await fetch(AppUrl.getsecques, {
			method: "POST",
			headers: { Authorization: "Token " + token },
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};

export const delete_ref = async (token) => {
	console.log("delete_ref,,,", token, AppUrl.delete_ref);
	try {
		const response = await fetch(AppUrl.delete_ref, {
			method: "POST",
			headers: { Authorization: "Token " + token },
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};

export const requestmobverfyotp = async (token) => {
	console.log("requestmobverfyotp,,,", token, AppUrl.requestmobverfyotp);
	try {
		const response = await fetch(AppUrl.requestmobverfyotp, {
			method: "POST",
			headers: { Authorization: "Token " + token },
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};

export const settings = async (token) => {
	console.log("settings,,,", token, AppUrl.settings);
	try {
		const response = await fetch(AppUrl.settings, {
			method: "POST",
			headers: { Authorization: "Token " + token },
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};

export const getsecqueslist = async (token) => {
	console.log("getsecqueslist,,,", token, AppUrl.getsecqueslist);
	try {
		const response = await fetch(AppUrl.getsecqueslist, {
			method: "POST",
			headers: { Authorization: "Token " + token },
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};

export const requestapprovalotp = async (token) => {
	console.log("requestapprovalotp,,,", token, AppUrl.requestapprovalotp);
	try {
		const response = await fetch(AppUrl.requestapprovalotp, {
			method: "POST",
			headers: { Authorization: "Token " + token },
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};

export const uploadmypic = async (body, token) => {
	console.log("uploadmypic,,,", token, AppUrl.uploadmypic);
	try {
		const response = await fetch(AppUrl.uploadmypic, {
			method: "POST",
			headers: {
				Authorization: "Token " + token,
			},
			body: body,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};

export const checksecques = async (body, token) => {
	console.log("checksecques,,,", body, AppUrl.checksecques);
	try {
		const response = await fetch(AppUrl.checksecques, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Token " + token,
			},
			body: body,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};

export const getinvoice = async (body, token) => {
	console.log("getinvoice,,,", body, AppUrl.getinvoice);
	try {
		const response = await fetch(AppUrl.getinvoice, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Token " + token,
			},
			body: body,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const viewcert = async (body, token) => {
	console.log("viewcert,,,", body, AppUrl.viewcert);
	try {
		const response = await fetch(AppUrl.viewcert, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Token " + token,
			},
			body: body,
		});

		// Check the response content type
		const contentType = response.headers.get("content-type");

		if (contentType && contentType.includes("application/json")) {
			// If response is JSON, parse it as JSON
			const jsonResponse = await response.json();
			return jsonResponse;
		} else if (contentType && contentType.includes("application/pdf")) {
			// If response is PDF, handle it as a blob or binary data
			const pdfBlob = await response.blob();

			// Create a URL for the PDF blob
			const pdfUrl = URL.createObjectURL(pdfBlob);

			// Optionally: Automatically open the PDF in a new tab
			window.open(pdfUrl);

			// Return or handle the PDF blob as needed
			return pdfBlob;
		} else {
			console.log("Unexpected content type:", contentType);
			return null;
		}
	} catch (e) {
		console.log(e);
		return null;
	}
};

export const approve = async (body, token) => {
	console.log("approve,,,", body, AppUrl.approve);
	try {
		const response = await fetch(AppUrl.approve, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Token " + token,
			},
			body: body,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};

export const addservice = async (body, token) => {
	console.log("addservice,,,", body, AppUrl.addservice);
	try {
		const response = await fetch(AppUrl.addservice, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Token " + token,
			},
			body: body,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};

export const changesecques = async (body, token) => {
	console.log("changesecques,,,", body, AppUrl.changesecques);
	try {
		const response = await fetch(AppUrl.changesecques, {
			method: "POST",
			headers: {
				Authorization: "Token " + token,
			},
			body: body,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};

export const changepass = async (body, token) => {
	console.log("changepass,,,", body, AppUrl.changepass);
	try {
		const response = await fetch(AppUrl.changepass, {
			method: "POST",
			headers: {
				Authorization: "Token " + token,
			},
			body: body,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const viewinvoice = async (body, token) => {
	console.log("viewinvoice,,,", body, AppUrl.viewinvoice);
	try {
		const response = await fetch(AppUrl.viewinvoice, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Token " + token,
			},
			body: body,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};

export const removeservice = async (body, token) => {
	console.log("removeservice,,,", body, AppUrl.removeservice);
	try {
		const response = await fetch(AppUrl.removeservice, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Token " + token,
			},
			body: body,
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};

export const convertToCorporate = async (token) => {
	try {
		const response = await fetch(AppUrl.convert_to_corporate, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Token " + token,
			},
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};

export const GetPaymentStatus = async (orderid) => {
	let token = await localStorage.getItem("key");
	try {
		const response = await fetch(AppUrl.getpaymentdata, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Token " + token,
			},
			body: JSON.stringify({ orderid: orderid }),
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const GetDraftMail = async (token) => {
	console.log("getdraftmails,,,", token, AppUrl.getdraftmails);
	try {
		const response = await fetch(AppUrl.getdraftmails, {
			method: "POST",
			headers: { Authorization: "Token " + token },
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};

export const GetDraftData = async (orderid) => {
	let token = await localStorage.getItem("key");
	try {
		const response = await fetch(AppUrl.draftData, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Token " + token,
			},
			body: JSON.stringify({ oid: orderid }),
		});
		const response_1 = await response.json();
		return response_1;
	} catch (e) {
		return console.log(e);
	}
};
export const getCertificate = async (data) => {
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	try {
		const res = await fetch(AppUrl.viewcertify, {
			method: "POST",
			headers: myHeaders,
			body: data,
		});
		const res_1 = await res.json();
		return res_1;
	} catch (e) {
		return console.log(e);
	}
};
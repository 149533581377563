import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useDispatch } from "react-redux";
import { setLanguage } from "../../../../../redux/Actions/UserAction";

const PageWrapper = ({
	children
}) => {
	const dispatch = useDispatch();
	const [language, setLanguages] = useState("en");

	useEffect(() => {
		getLang();
	}, []);
	const getLang = () => {
		const res = localStorage.getItem("lang");
		if (res == "Hindi") {
			setLanguages("hi");
			dispatch(setLanguage("Hindi"));
		} else {
			setLanguages("en");
			dispatch(setLanguage("English"));
		}
	};

	const handleLanguageChange = (language) => {
		if (language === "hi") {
			dispatch(setLanguage("Hindi"));
			localStorage.setItem("lang", "Hindi");
		} else {
			dispatch(setLanguage("English"));
			localStorage.setItem("lang", "English");
		}
		setLanguages(language);
	};

	return (
		<div className="App">
			<Header handleLanguageChange={handleLanguageChange} language={language} />
			{children}
			<Footer />
		</div>
	);
};

export default PageWrapper;

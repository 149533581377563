import React from "react";
import styles from "../styles/Footer.module.css";
import logo from "../../../assets/images/hrlogo.png";
import facebook from "../../../assets/images/facebook.png";
import linkedin from "../../../assets/images/linkedin.png";
import twitter from "../../../assets/images/twitter.png";
import youtube from "../../../assets/images/youtube.png";
import useLocale from "../../../../utility/useLocal";
const socialMediaLinks = [
  {
    href: "https://www.facebook.com/certificcs",
    src: facebook,
    alt: "Facebook Logo",
  },
  {
    href: "https://www.twitter.com/certificcs",
    src: twitter,
    alt: "Twitter Logo",
  },
  {
    href: "https://www.youtube.com/certificcs",
    src: youtube,
    alt: "YouTube Logo",
  },
  {
    href: "https://www.linkedin.com/company/certificcs",
    src: linkedin,
    alt: "LinkedIn Logo",
  },
];
const quickLinks = [
  "Home",
  "About",
  "Services",
  "Privacy Policy",
  "Terms & Conditions",
];
const Footer = () => {
  const {
    Footer_Logo_Content,
    Company,
    Address,
    QuickLinks,
    About,
    Home,
    Services,
    PrivacyPolicy,
    Terms_Conditions,
    ContactUs,
  } = useLocale();
  const quickLinks = [
    About,
    Home,
    Services,
    PrivacyPolicy,
    Terms_Conditions,
  ];
  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.column}>
          <img src={logo} alt="Logo" className={styles.logoImage} />
          <p className={styles.logoText}>{Footer_Logo_Content}</p>
        </div>
        <div className={styles.column}>
          <h3> {Company}</h3>
          <p className={`${styles.logoText} ${styles.links}`}>{Address}</p>
          <p
            className={`${styles.logoText} ${styles.links} ${styles.emailText}`}
          >
            info@synerthinksolutions.com
          </p>
        </div>
        {
          <div className={styles.column}>
            <h3>{QuickLinks}</h3>
            <ul>
              {quickLinks.map((link, index) => (
                <li key={index} className={styles.links}>
                  {link}
                </li>
              ))}
            </ul>
          </div>
        }
        {
          <div className={styles.column}>
            <h3>{ContactUs}</h3>
            <div className={styles.socialIconsWrapper}>
              {socialMediaLinks.map((social, index) => (
                <div key={index} className={styles.socialIconsContainer}>
                  <a
                    href={social.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={social.src}
                      alt={social.alt}
                      className={`${styles.socialIcons} ${styles.links}`}
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
        }
      </footer>
      <div className={styles.line} />
      <div className={styles.footerBottomTextContainer}>
        <span className={styles.footerBottomText}>All rights reserved.</span>
      </div>
    </>
  );
};

export default Footer;

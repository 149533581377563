import React, { useState } from "react";
import "./login.css";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import username from "../../assets/images/username.png";
import email from "../../assets/images/white-email.png";
import phone from "../../assets/images/phone.png";
import location from "../../assets/images/location.png";
import CryptoJS from "crypto-js";
import { register } from "../../API";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Registration = () => {
	const { locale } = useSelector((state) => state.user);

	const [registrationCredential, setRegistrationCredential] = useState({
		first_name: "",
		last_name: "",
		email: "",
		password: "",
		confirmPassword: "",
		contactNumber: "",
		SecurityAnswer1: "",
		SecurityAnswer2: "",
		Address1: "",
		Address2: "",
		ReferralCode: "",
		SecretQuestion1Answer: "",
		SecretQuestion2Answer: "",
		userservice: ["1", "2"],
	});
	const [pass, setPass] = useState({});
	const [isChecked, setIsChecked] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const navigate = useNavigate();
	const secretQuestions1 = [
		"What is your birth date?",
		"What is your birth place?",
		"What is your birth state capital?",
		"What is your favourite color?",
		"What is your favourite destination place?",
		"What is your favourite food?",
		"What is your favourite movie?",
		"What is your favourite sport?",
		"What is your first car brand?",
		"What is your first car color?",
		"What is your first mobile brand?",
	];

	const secretQuestions2 = [
		"What is your first pet name?",
		"What is your first school name?",
		"What is your graduation percentage?",
		"What is your spouse birth date?",
		"What is your spouse birth place?",
		"Where did you go on your first international trip?",
		"Where did you study 6th grade?",
		"Who is your favourite actor?",
		"Who is your favourite sports person?",
	];

	const onChangeText = (val, key_name) => {
		setRegistrationCredential((previous) => ({
			...previous,
			[key_name]: val,
		}));

		if (key_name === "password" || key_name === "cpassword") {
			var encryptedPassword = CryptoJS.SHA1(val);
			setPass((previous) => ({
				...previous,
				[key_name]: encryptedPassword,
			}));
		}
	};

	const toggleUserService = (item) => {
		setRegistrationCredential((previous) => {
			const { userservice } = previous;
			if (
				userservice.includes(item) &&
				registrationCredential?.userservice?.length > 1
			) {
				return {
					...previous,
					userservice: userservice.filter((service) => service !== item),
				};
			} else if (!registrationCredential?.userservice?.includes(item)) {
				return {
					...previous,
					userservice: [...userservice, item],
				};
			} else {
				toast.error("At least one service should be active.");
				return {
					...previous,
					userservice: [...userservice],
				};
			}
		});
	};

	const handleCheckboxChange = () => {
		setIsChecked(!isChecked);
	};

	const handleLogin = async () => {
		const {
			first_name,
			last_name,
			email,
			password,
			cpassword,
			contactNumber,
			SecurityAnswer1,
			SecurityAnswer2,
			Address1,
			Address2,
			SecretQuestion1Answer,
			SecretQuestion2Answer,
		} = registrationCredential;

		if (password === cpassword) {
			if (
				first_name &&
				last_name &&
				email &&
				password &&
				cpassword &&
				contactNumber &&
				SecretQuestion1Answer &&
				SecurityAnswer1 &&
				SecretQuestion2Answer &&
				SecurityAnswer2 &&
				Address1 &&
				Address2 &&
				isChecked
			) {
				const formData = new FormData();
				formData.append("password", pass?.password);
				const passwordHash = formData.get("password");
				const data = {
					first_name,
					last_name,
					email,
					password: passwordHash,
					contactNumber,
					question_1: SecretQuestion1Answer,
					answer_1: SecurityAnswer1,
					question_2: SecretQuestion2Answer,
					answer_2: SecurityAnswer2,
					address_1: Address1,
					address_2: Address2,
				};
				const emailRegExp =
					/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				const uppercaseRegExp = /(?=.*?[A-Z])/;
				const lowercaseRegExp = /(?=.*?[a-z])/;
				const digitsRegExp = /(?=.*?[0-9])/;
				const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
				const minLengthRegExp = /.{8,}/;
				const mobval = /.{10,}/;

				const uppercasePassword = uppercaseRegExp.test(password);
				const lowercasePassword = lowercaseRegExp.test(password);
				const digitsPassword = digitsRegExp.test(password);
				const specialCharPassword = specialCharRegExp.test(password);
				const minLengthPassword = minLengthRegExp.test(password);
				const mobvalidate = mobval.test(contactNumber);
				const emailValidate = emailRegExp.test(email);

				if (
					uppercasePassword &&
					lowercasePassword &&
					digitsPassword &&
					specialCharPassword &&
					minLengthPassword &&
					mobvalidate &&
					emailValidate
				) {
					try {
						setLoading(true);
						const response = await register(JSON.stringify(data));
						setLoading(false);
						if (response?.response === "ok") {
							navigate("/agreement", {
								state: {
									data: response?.data,
									userData: { first_name, email, contactNumber },
								},
							});
						} else {
							toast.error(response.response);
						}
					} catch (error) {
						setLoading(false);
						toast.error("An error occurred: " + error.message);
					}
				} else {
					toast.error("Please validate the input fields");
				}
			} else {
				toast.error("All fields are mandatory");
			}
		} else {
			toast.error("Confirm password didn't match");
		}
	};

	return (
		<div className="wrapper">
			<img
				alt="img"
				src={require("../../assets/images/thumb.jpg")}
				style={{
					height: "100vh",
					width: "100%",
					opacity: 0.9,
					position: "absolute",
					zIndex: -1,
				}}
			/>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					margin: "auto",
					alignItems: "center",
					height: "100vh",
				}}
			>
				<div className="card">
					<div style={{ textAlign: "center" }}>
						<img
							alt="img"
							src={require("../../assets/images/logo.png")}
							className="logo"
						/>
						<p className="welcome">{locale?.RegisterNewAccount}</p>
					</div>

					<div
						style={{
							display: "flex",
							flexDirection: "column",
							width: "100%",
							margin: "auto",
						}}
					>
						<Input
							icon={username}
							placeholder={locale?.FirstName}
							value={registrationCredential.first_name}
							key_name="first_name"
							onChangeText={onChangeText}
						/>
						<Input
							icon={username}
							value={registrationCredential.last_name}
							placeholder={locale?.LastName}
							key_name="last_name"
							onChangeText={onChangeText}
						/>
						<Input
							icon={email}
							value={registrationCredential.email}
							placeholder={locale?.EnterEmail}
							key_name="email"
							onChangeText={onChangeText}
						/>
						<Input
							type={"password"}
							value={registrationCredential.password}
							placeholder={locale?.EnterPassword}
							key_name="password"
							onChangeText={onChangeText}
						/>
						<p className="password-content">{locale?.PassDesc}</p>

						<Input
							type={"password"}
							placeholder={locale?.ConfirmPassword}
							value={registrationCredential.cpassword}
							key_name="cpassword"
							onChangeText={onChangeText}
						/>
						<Input
							icon={phone}
							placeholder={locale?.ContactNumber}
							value={registrationCredential.contactNumber}
							key_name="contactNumber"
							onChangeText={onChangeText}
						/>

						<div
							onClick={() => {
								setRegistrationCredential((previous) => ({
									...previous,
									["SecretQuestion1"]: !registrationCredential?.SecretQuestion1,
									["SecretQuestion2"]: false,
								}));
							}}
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								padding: 10,
								justifyContent: "space-between",
								width: "76%",
								backgroundColor: "rgba(20,97,156, 0.9)",
								borderRadius: 10,
								color: "#fff",
								cursor: "pointer",
								marginBottom: 10,
								margin: "auto",
							}}
						>
							<span>
								{registrationCredential?.SecretQuestion1Answer
									? registrationCredential?.SecretQuestion1Answer
									: locale?.SecretQuestion + " 1"}
							</span>
							<i class="fa fa-angle-down" aria-hidden="true"></i>
						</div>
						<div
							className={`secretQuestion ${
								registrationCredential?.SecretQuestion1 ? "" : "hidden"
							}`}
							style={{ margin: "auto", marginBottom: 10 }}
						>
							{secretQuestions1.map((question, index) => (
								<p
									onClick={() => {
										setRegistrationCredential((previous) => ({
											...previous,
											["SecretQuestion1Answer"]: question,
											["SecretQuestion1"]: false,
										}));
									}}
									key={index}
									style={{
										margin: 4,
										backgroundColor: "rgba(0,0,0,0.04)",
										padding: 8,
										borderRadius: 6,
										cursor: "pointer",
										color: "rgba(0,0,0,0.7)",
									}}
								>
									{question}
								</p>
							))}
						</div>
						{registrationCredential?.SecretQuestion1Answer && (
							<div style={{ marginBottom: 10, width: "100%" }}>
								<Input
									icon={username}
									value={registrationCredential?.SecurityAnswer1}
									placeholder={locale?.EnterAnswer}
									onChangeText={(val, key_name) => {
										setRegistrationCredential((previous) => ({
											...previous,
											[key_name]: val,
										}));
									}}
									key_name={"SecurityAnswer1"}
								/>
							</div>
						)}

						<div
							onClick={() => {
								setRegistrationCredential((previous) => ({
									...previous,
									["SecretQuestion2"]: !registrationCredential?.SecretQuestion2,
									["SecretQuestion1"]: false,
								}));
							}}
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								padding: 10,
								justifyContent: "space-between",
								width: "76%",
								backgroundColor: "rgba(20,97,156, 0.9)",
								borderRadius: 10,
								color: "#fff",
								cursor: "pointer",
								marginBottom: 10,
								margin: "auto",
							}}
						>
							<span>
								{registrationCredential?.SecretQuestion2Answer
									? registrationCredential?.SecretQuestion2Answer
									: locale?.SecretQuestion + " 2"}
							</span>
							<i class="fa fa-angle-down" aria-hidden="true"></i>
						</div>
						<div
							className={`secretQuestion ${
								registrationCredential?.SecretQuestion2 ? "" : "hidden"
							}`}
							style={{ margin: "auto", marginBottom: 10 }}
						>
							{secretQuestions2.map((question, index) => (
								<p
									onClick={() => {
										setRegistrationCredential((previous) => ({
											...previous,
											["SecretQuestion2Answer"]: question,
											["SecretQuestion2"]: false,
										}));
									}}
									key={index}
									style={{
										margin: 4,
										backgroundColor: "rgba(0,0,0,0.04)",
										padding: 8,
										borderRadius: 6,
										cursor: "pointer",
										color: "rgba(0,0,0,0.7)",
									}}
								>
									{question}
								</p>
							))}
						</div>
						{registrationCredential?.SecretQuestion2Answer && (
							<div style={{ marginBottom: 10, width: "100%" }}>
								<Input
									icon={username}
									value={registrationCredential?.SecurityAnswer2}
									placeholder={locale?.EnterAnswer}
									onChangeText={(val, key_name) => {
										setRegistrationCredential((previous) => ({
											...previous,
											[key_name]: val,
										}));
									}}
									key_name={"SecurityAnswer2"}
								/>
							</div>
						)}
						<Input
							icon={location}
							placeholder={locale?.Address + " 1"}
							value={registrationCredential.Address1}
							key_name="Address1"
							onChangeText={onChangeText}
						/>
						<Input
							icon={location}
							placeholder={locale?.Address + " 2"}
							value={registrationCredential.Address2}
							key_name="Address2"
							onChangeText={onChangeText}
						/>
					</div>

					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							marginTop: 10,
							width: "90%",
							margin: "auto",
						}}
					>
						<input
							type="checkbox"
							checked={isChecked}
							onChange={handleCheckboxChange}
							style={{ marginRight: 5 }}
						/>
						<p className="policy-content">
							{locale?.ByClick} {locale?.terms} {locale?.AndRead}
							{locale?.DataPolicy}
						</p>
					</div>

					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button onClick={handleLogin} name={locale?.SignAgreement} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Registration;
